import React from 'react'
import { GetServerSideProps } from 'next'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { Button, Heading, Panel } from '@afterpaytouch/core'
import { ConsumerPage } from '../src/model'
import { Head } from '../src/component'
import { useConsumerSizes } from '../src/utils/responsive'
import { Route } from '../src/config/router'
import { getTheme } from '../src/utils/server/convergence'
import { useDisplayIllustrations } from '../src/state/convergence/hooks'

const I18N_NAMESPACE = ['common', 'terminalError']

const NotFoundPage: ConsumerPage = () => {
  const { t } = useTranslation(I18N_NAMESPACE)
  const isDisplayIllustrations = useDisplayIllustrations()
  const router = useRouter()
  const { sectionHeadingSize } = useConsumerSizes()
  const handleReturn = (): void => {
    router.push(Route.INDEX)
  }

  const pageTitle = `${t('terminalError:pageNotFound:title')} | ${t('common:brandName')}`

  return (
    <>
      <Head title={pageTitle} />
      <Panel>
        {/* @ts-ignore: OPERATION BLEED STOPPER */}
        <Panel.Content>
          <div className='mt-5 text-center md:mt-10'>
            {isDisplayIllustrations && (
              <div className='mb-10'>
                <Image
                  width={224}
                  height={200}
                  src={`/images/not-found.svg`}
                  alt=''
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
            )}

            <div className='mb-10'>
              <Heading size={sectionHeadingSize}>{t('terminalError:pageNotFound:heading')}</Heading>
            </div>
            <div className='mb-5 md:mb-10'>
              <Button.Secondary size='Regular' onClick={handleReturn}>
                {t('terminalError:pageNotFound:cta')}
              </Button.Secondary>
            </div>
          </div>
        </Panel.Content>
      </Panel>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { theme } = await getTheme(context)
  return {
    props: {
      theme,
      ...(await serverSideTranslations(context.locale as string, I18N_NAMESPACE)),
    },
  }
}

export default NotFoundPage
